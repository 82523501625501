import ParameterRow from './ParameterRow.tsx';
import { Parameter } from './Types.ts';

interface ParameterTableProps {
  parameters?: Parameter[];
  selectedParameters: any[];
  onParameterSelection: (parameter: Parameter, selectedValue: string) => void;
}

export default function ParameterTable({ parameters, selectedParameters, onParameterSelection }: ParameterTableProps) {
  return parameters?.length ? (
    <div class="overflow-x-auto shadow-md rounded-lg">
      <table class="min-w-full bg-white  text-sm">
        <thead>
          <tr>
            <th class="py-2 px-4 border-b text-left font-bold">Name</th>
            <th class="py-2 px-4 border-b text-left font-bold">Description</th>
          </tr>
        </thead>
        <tbody>
          {parameters.map((param, index) => (
            <ParameterRow
              key={index}
              param={param}
              selectedValue={selectedParameters.find((p) => p.name === param.name)?.value || ''}
              onParameterSelection={onParameterSelection}
              index={index}
            />
          ))}
        </tbody>
      </table>
    </div>
  ) : (
    <p class="text-slate-600 text-sm md:text-base lg:text-lg">No parameters for this endpoint.</p>
  );
}
